import React, { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { Avatar, Box, Grid, useMediaQuery, useTheme, styled } from '@mui/material';
import { graphql } from 'gatsby';
import { SwLatestContent } from '@lib/components/SwLatestContent';
import { SwRecordCard } from '@lib/components/SwRecordCard';
import { colors } from '@lib/components/bedrock/fundations/colors';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import SwChangeMaker from '../app/about/SwChangeMaker';
import SwImpact from '../app/about/SwImpact';
import SwInvestors from '../app/about/SwInvestors';
import SwLeadership from '../app/about/SwLeadership';
import FlashBanner from '../app/common/components/FlashBanner';
import Seo from '../app/common/components/Seo';
import companyIcon from '../assets/icons/company-icon.png';
import teamIcon from '../assets/icons/team-icon.png';
import aboutPreview from '../assets/images/about_preview.png';
import SwContainer from '../components/v1/SwContainer';
import SwLayout from '../components/v1/SwLayout';
import SwLine from '../components/v1/SwLine';
import SwSubMenu from '../components/v1/website/SwSubMenu';
import SwTopNav from '../components/v1/website/SwTopNav';
import SwWebsiteFooter from '../components/v1/website/SwWebsiteFooter';
import SwSectionFeatures from '../components/v1/website/features/SwSectionFeatures';
import { grey3, grey5, grey6, skyBlueStrong, white } from '../lib/components/bedrock/SwColors';
import SwSpacer from '../lib/components/bedrock/layout/SwSpacer';
import SwLink from '../lib/components/bedrock/typography/SwLink';
import SwTypography from '../lib/components/bedrock/typography/SwTypography';
import SwWebsiteContainer from '../lib/components/layout/SwWebsiteContainer';

const Page = styled('div')`
    background-color: ${grey6};
`;

const StyledPreview = styled('img')`
    background-color: ${skyBlueStrong};
    width: 100%;
`;

const StyledAvatar = styled(Avatar)`
    height: 44px;
    width: 44px;
`;

const SectionContainer = styled(Box)`
    padding-block: ${({ theme }) => theme.spacing(8)};
`;

const StyledBelief = styled(SwTypography)`
    background-color: ${white};
    border-radius: 8px;
    padding: ${({ theme }) => theme.spacing(2)};
`;

const StyledImage = styled('div')<{ backgroundColor: string }>`
    background-color: ${({ backgroundColor }) => backgroundColor};
    height: 457px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Header = styled('div')`
    padding-block-start: 64px;
    padding-block-end: 64px;
`;

enum Sections {
    MISSION = 'mission',
    COMPANY = 'company',
    TEAM = 'team',
    IMPACT = 'impact',
    JOIN = 'join'
}

const AboutPage = ({ data }) => {
    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const { title, subtitle } = data.page;

    const missionRef = useRef(null);
    const companyRef = useRef(null);
    const teamRef = useRef(null);
    const impactRef = useRef(null);
    const joinRef = useRef(null);
    const { t } = useTranslation();

    const [isVisibleOb, setIsVisibleOb] = useState(null);

    const isVisible = (inView: boolean, entry: any) => {
        setIsVisibleOb({ inView: inView, entry: entry });
    };

    return (
        <>
            <FlashBanner banner={data.flashBanners.edges[0].node} />
            <SwTopNav
                activePage={WEBSITE_MENU_ITEM.about.key}
                highlightedPage={data.highlightedPage?.nodes?.at(0)}
                latestUpdates={data.latestArticles.nodes}
            />
            <SwWebsiteContainer>
                <SwLayout>
                    <Page>
                        <SwContainer withMaxWidth={true}>
                            <Header>
                                <SwTypography color={colors.common.black} component={'h1'} variant={'h3'}>
                                    {title}
                                </SwTypography>
                                <SwTypography color={colors.text.secondary} component={'p'} variant={'h3'}>
                                    {subtitle}
                                </SwTypography>
                            </Header>
                        </SwContainer>
                        <SwSubMenu
                            isVisibleEntry={isVisibleOb}
                            refs={[missionRef, companyRef, teamRef, impactRef, joinRef]}
                            tabs={[t('mission'), t('company'), t('team'), t('impact'), t('join_us')]}
                            tabsId={[Sections.MISSION, Sections.COMPANY, Sections.TEAM, Sections.IMPACT, Sections.JOIN]}
                            withMaxWidth={true}
                        />
                        <InView as={'div'} id={Sections.MISSION} rootMargin={'-64px 0px 0px 0px'} onChange={isVisible}>
                            <div ref={missionRef}>
                                <StyledPreview alt={'About Preview'} src={aboutPreview} />
                                <SwContainer withMaxWidth={true}>
                                    <Grid container={true}>
                                        <SwLine color={grey5} spacing={4} />

                                        <Grid item={true} xs={11}>
                                            <Grid container={true}>
                                                <Grid item={true} md={4} xs={12}>
                                                    <SwTypography component={'h2'} variant={'h3'}>
                                                        {t('company_page.section_1_title')}
                                                    </SwTypography>

                                                    <SwSpacer direction={'vertical'} spacing={2} />

                                                    <SwTypography color={grey3} variant={'body1'}>
                                                        {t('company_page.section_1_subtitle')}
                                                    </SwTypography>

                                                    <SwSpacer direction={'vertical'} spacing={4} />

                                                    <Box display={'flex'} gap={2}>
                                                        <StyledAvatar
                                                            alt={'Rachel Delacour'}
                                                            src={
                                                                'https://sweep.imgix.net/sweepAvatar120.jpg?auto=format&fit=clip&w=256'
                                                            }
                                                        />

                                                        <Box>
                                                            <SwTypography component={'p'} variant={'h5'}>
                                                                Rachel Delacour
                                                            </SwTypography>
                                                            <SwTypography>Cofounder and CEO</SwTypography>
                                                        </Box>
                                                    </Box>
                                                </Grid>

                                                <Grid item={true} xs={1}></Grid>

                                                <Grid item={true} md={7} xs={12}>
                                                    {isSmallScreen && <SwSpacer direction={'vertical'} spacing={4} />}
                                                    <SwTypography component={'p'} variant={'h3'}>
                                                        {t('company_page.section_1_quote_1')}
                                                    </SwTypography>

                                                    <SwSpacer direction={'vertical'} spacing={4} />

                                                    <SwTypography color={grey3} component={'p'} variant={'h3'}>
                                                        {t('company_page.section_1_quote_2')}
                                                        <SwSpacer direction={'vertical'} spacing={4} />
                                                        {t('company_page.section_1_quote_3')}
                                                        <SwSpacer direction={'vertical'} spacing={4} />
                                                        {t('company_page.section_1_quote_4')}
                                                        <SwSpacer direction={'vertical'} spacing={4} />
                                                        {t('company_page.section_1_quote_5')}
                                                        <SwSpacer direction={'vertical'} spacing={4} />
                                                        {t('company_page.section_1_quote_6')}
                                                    </SwTypography>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <SwLine color={grey5} spacingTop={8} />

                                        <Box display={'flex'} flexDirection={'column'} gap={'16px'} marginBottom={8}>
                                            <SwTypography color={grey3} variant={'body1'}>
                                                {t('company_page.our_beliefs')}
                                            </SwTypography>

                                            <Grid container={true} spacing={2}>
                                                <Grid item={true} md={3} xs={12}>
                                                    <StyledBelief color={grey3} component={'p'} variant={'h4'}>
                                                        {t('company_page.belief_card_1')}
                                                    </StyledBelief>
                                                </Grid>

                                                <Grid item={true} md={3} xs={12}>
                                                    <StyledBelief color={grey3} component={'p'} variant={'h4'}>
                                                        {t('company_page.belief_card_2')}
                                                    </StyledBelief>
                                                </Grid>

                                                <Grid item={true} md={3} xs={12}>
                                                    <StyledBelief color={grey3} component={'p'} variant={'h4'}>
                                                        {t('company_page.belief_card_3')}
                                                    </StyledBelief>
                                                </Grid>

                                                <Grid item={true} md={3} xs={12}>
                                                    <StyledBelief color={grey3} component={'p'} variant={'h4'}>
                                                        {t('company_page.belief_card_4')}
                                                    </StyledBelief>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </SwContainer>
                            </div>
                        </InView>

                        <InView as={'div'} id={Sections.COMPANY} rootMargin={'-64px 0px 0px 0px'} onChange={isVisible}>
                            <SectionContainer bgcolor={grey5} ref={companyRef}>
                                <SwContainer withMaxWidth={true}>
                                    <Grid container={true}>
                                        <Grid item={true} xs={12}>
                                            <Grid container={true} spacing={3}>
                                                <Grid
                                                    container={true}
                                                    direction={'column'}
                                                    item={true}
                                                    justifyContent={'flex-start'}
                                                    md={3}
                                                    xs={12}
                                                >
                                                    <Grid item={true}>
                                                        <SwTypography component={'h2'} variant={'h3'}>
                                                            {t('company_page.section_2_title')}
                                                        </SwTypography>
                                                    </Grid>
                                                    <SwSpacer direction={'vertical'} spacing={2} />
                                                    <Grid item={true}>
                                                        <SwTypography color={grey3} variant={'body2'}>
                                                            {t('company_page.section_2_subtitle')}
                                                        </SwTypography>
                                                    </Grid>
                                                </Grid>
                                                <Grid item={true} md={9} xs={12}>
                                                    <StyledImage backgroundColor={skyBlueStrong}>
                                                        <img alt={'Company icon'} src={companyIcon} width={350} />
                                                    </StyledImage>
                                                </Grid>
                                            </Grid>
                                            <SwSpacer direction={'vertical'} spacing={4} />
                                        </Grid>

                                        <SwSectionFeatures
                                            background={true}
                                            features={[
                                                {
                                                    title: t('company_page.section_2_feature_card_1_title'),
                                                    description: (
                                                        <SwTypography color={grey3}>
                                                            <Trans
                                                                i18nKey={
                                                                    'company_page.section_2_feature_card_1_content'
                                                                }
                                                            >
                                                                <SwLink
                                                                    color={grey3}
                                                                    href={'https://www.sweep.net/'}
                                                                    useNewTab={false}
                                                                />
                                                            </Trans>
                                                        </SwTypography>
                                                    )
                                                },
                                                {
                                                    title: t('company_page.section_2_feature_card_2_title'),
                                                    description: (
                                                        <SwTypography color={grey3}>
                                                            {t('company_page.section_2_feature_card_2_content')}
                                                        </SwTypography>
                                                    )
                                                },
                                                {
                                                    title: t('company_page.section_2_feature_card_3_title'),
                                                    description: (
                                                        <SwTypography color={grey3}>
                                                            {t('company_page.section_2_feature_card_3_content')}
                                                        </SwTypography>
                                                    )
                                                },
                                                {
                                                    title: t('company_page.section_2_feature_card_4_title'),
                                                    description: (
                                                        <SwTypography color={grey3}>
                                                            <Trans
                                                                i18nKey={
                                                                    'company_page.section_2_feature_card_4_content'
                                                                }
                                                            >
                                                                <SwLink
                                                                    color={grey3}
                                                                    href={'/contact'}
                                                                    useNewTab={false}
                                                                />
                                                            </Trans>
                                                        </SwTypography>
                                                    )
                                                }
                                            ]}
                                            spacing={2}
                                        />

                                        <SwLine color={white} spacingTop={5} />

                                        <SwTypography
                                            bold={true}
                                            color={grey3}
                                            component={'h3'}
                                            uppercase={true}
                                            variant={'caption'}
                                        >
                                            {t('the_latest_sweep_updates')}
                                        </SwTypography>

                                        <Grid item={true} xs={12}>
                                            <SwSpacer direction={'vertical'} spacing={4} />
                                        </Grid>
                                    </Grid>
                                </SwContainer>
                                <SwContainer noGutter={isSmallScreen} withMaxWidth={true}>
                                    <SwLatestContent
                                        content={data.latestUpdates.nodes}
                                        renderItems={(article) => (
                                            <SwRecordCard
                                                baseUrl={WEBSITE_MENU_ITEM[article.category.usage.toLowerCase()]?.link}
                                                key={article.id}
                                                record={article}
                                                titleHeading={'h4'}
                                                width={{ xs: 300, md: 'auto' }}
                                            />
                                        )}
                                    />
                                </SwContainer>
                            </SectionContainer>
                        </InView>

                        <InView as={'div'} id={Sections.TEAM} rootMargin={'-96px 0px 0px 0px'} onChange={isVisible}>
                            <SectionContainer ref={teamRef}>
                                <SwContainer withMaxWidth={true}>
                                    <Grid container={true} spacing={3}>
                                        <Grid container={true} direction={'column'} item={true} md={3} xs={12}>
                                            <Grid item={true}>
                                                <SwTypography component={'h2'} variant={'h3'}>
                                                    {t('company_page.section_3_title')}
                                                </SwTypography>
                                            </Grid>

                                            <SwSpacer direction={'vertical'} spacing={2} />

                                            <Grid item={true}>
                                                <SwTypography color={grey3} variant={'body2'}>
                                                    {t('company_page.section_3_subtitle')}
                                                </SwTypography>
                                            </Grid>
                                        </Grid>
                                        <Grid item={true} md={9} xs={12}>
                                            <StyledImage backgroundColor={skyBlueStrong}>
                                                <img alt={'Team icon'} src={teamIcon} width={350} />
                                            </StyledImage>
                                        </Grid>
                                    </Grid>
                                </SwContainer>

                                <SwContainer withMaxWidth={true}>
                                    <Grid container={true}>
                                        <SwLeadership leadershipsEdges={data.leaderships} />

                                        <Grid item={true} xs={12}>
                                            <SwLine color={grey5} spacingTop={4} />

                                            <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
                                                <SwTypography color={grey3} component={'h3'} variant={'h4'}>
                                                    {t('company_page.section_4_title')}
                                                </SwTypography>

                                                <Grid item={true} md={4} xs={12}>
                                                    <SwTypography color={grey3} variant={'body1'}>
                                                        {t('company_page.section_4_subtitle')}
                                                    </SwTypography>
                                                </Grid>
                                            </Box>

                                            <SwSpacer direction={'vertical'} spacing={4} />

                                            <SwInvestors />
                                        </Grid>
                                    </Grid>
                                </SwContainer>
                            </SectionContainer>
                        </InView>

                        <InView as={'div'} id={Sections.IMPACT} rootMargin={'-96px 0px 0px 0px'} onChange={isVisible}>
                            <SectionContainer bgcolor={skyBlueStrong} ref={impactRef}>
                                <SwImpact />
                            </SectionContainer>
                        </InView>

                        <InView as={'div'} id={Sections.JOIN} rootMargin={'-96px 0px 0px 0px'} onChange={isVisible}>
                            <SectionContainer ref={joinRef}>
                                <SwChangeMaker />
                            </SectionContainer>
                        </InView>

                        <SwWebsiteFooter newsletterForm={data.newsletterForm} />
                    </Page>
                </SwLayout>
            </SwWebsiteContainer>
        </>
    );
};

export const Head = ({
    location: { pathname },
    data: {
        page: { seo },
        enPage: { seo: enSeo }
    }
}) => (
    <Seo
        description={seo.description}
        image={enSeo.image?.url}
        noIndex={seo.noIndex}
        pathname={pathname}
        title={seo.title}
    />
);

export const pageQuery = graphql`
    query getAboutPageInformation($locale: GraphCMS_Locale) {
        newsletterForm: hubspotForm(name: { eq: "Newsletter Sign-Up" }) {
            ...Essentials_HubspotForm
        }
        highlightedPage: allGraphCmsPage(
            limit: 1
            filter: { isNewPage: { eq: true } }
            sort: { order: DESC, fields: createdAt }
        ) {
            nodes {
                ...HighlightedPage_GraphCMS_Page
            }
        }
        flashBanners: allGraphCmsFlashBanner(
            sort: { fields: createdAt, order: DESC }
            filter: { locale: { eq: $locale } }
            limit: 1
        ) {
            edges {
                node {
                    ...Essentials_GraphCMS_FlashBanner
                }
            }
        }
        latestUpdates: allGraphCmsArticle(
            filter: {
                locale: { eq: $locale }
                actualLocale: { eq: $locale }
                industry: { id: { eq: null } }
                category: { usage: { eq: Newsroom } }
                seo: { noIndex: { ne: true } }
            }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        latestArticles: allGraphCmsArticle(
            filter: { locale: { eq: $locale }, industry: { id: { eq: null } }, seo: { noIndex: { ne: true } } }
            sort: { order: DESC, fields: published }
            limit: 4
        ) {
            nodes {
                ...ArticleCard_GraphCMS_Article
            }
        }
        leaderships: allGraphCmsPerson(
            filter: {
                locale: { eq: $locale }
                company: { name: { eq: "Sweep" } }
                lastName: {
                    in: [
                        "Delacour"
                        "Güller"
                        "Chaze"
                        "Denormandie"
                        "Brauch"
                        "Bettin"
                        "Vincent"
                        "Bouhadja"
                        "Valencia"
                    ]
                }
            }
        ) {
            edges {
                node {
                    id
                    firstName
                    lastName
                    role
                    photo {
                        alt
                        gatsbyImageData(aspectRatio: 1.778, layout: CONSTRAINED, width: 400, placeholder: BLURRED)
                    }
                }
            }
        }
        enPage: graphCmsPage(slug: { eq: "about" }, locale: { eq: en }) {
            seo {
                image {
                    url
                }
            }
        }
        page: graphCmsPage(slug: { eq: "about" }, locale: { eq: $locale }) {
            id
            remoteId
            slug
            subtitle
            title
            seo {
                ...Essentials_GraphCMS_Seo
            }
        }
    }
`;

export default AboutPage;
