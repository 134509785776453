import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, useMediaQuery, useTheme, styled } from '@mui/material';
import Coatue from '../../assets/images/coatue.png';
import AdrianMcdermott from '../../assets/images/profiles/adrian-mcdermott.png';
import BernardLiautaud from '../../assets/images/profiles/bernard-liautaud.png';
import GlennKelman from '../../assets/images/profiles/glenn-kelman.png';
import JaySimons from '../../assets/images/profiles/jay-simons.png';
import JeanetteFurstengerg from '../../assets/images/profiles/jeanette-furstenberg.png';
import MarieEkeland from '../../assets/images/profiles/marie-ekeland.png';
import NicolasDessaigne from '../../assets/images/profiles/nicolas-dessaigne.png';
import PhilippeLafont from '../../assets/images/profiles/philippe-laffont.png';
import PiaIribarne from '../../assets/images/profiles/pia-iribarne.png';
import TonyFadellThumbnail from '../../assets/images/profiles/tony-fadell-thumbnail.png';
import TonyFadell from '../../assets/images/profiles/tony-fadell.png';
import { grey3, grey5, white } from '../../lib/components/bedrock/SwColors';
import SwSpacer from '../../lib/components/bedrock/layout/SwSpacer';
import SwTypography from '../../lib/components/bedrock/typography/SwTypography';
import SwPeople from './SwPeople';

const StyledGrid = styled(Grid)`
    background-color: ${white};
    padding: ${({ theme }) => theme.spacing(2)};
    border-radius: 8px;
    min-height: 265px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const StyledInvestor = styled(Grid)`
    margin-bottom: 8px;
`;

const StyledBox = styled(Box)`
    background-color: ${grey5};
    border-radius: 8px;
`;

const investors = [
    {
        img: BernardLiautaud,
        title: <SwTypography bold={true}>Bernard Liautaud</SwTypography>,
        subTitle: <SwTypography color={grey3}>Balderton</SwTypography>
    },
    {
        img: PiaIribarne,
        title: <SwTypography bold={true}>Pia d’Iribarne</SwTypography>,
        subTitle: <SwTypography color={grey3}>New Wave</SwTypography>
    },
    {
        img: JeanetteFurstengerg,
        title: <SwTypography bold={true}>Jeannette zu Fürstenberg</SwTypography>,
        subTitle: <SwTypography color={grey3}>La Famiglia</SwTypography>
    },
    {
        img: MarieEkeland,
        title: <SwTypography bold={true}>Marie Ekeland</SwTypography>,
        subTitle: <SwTypography color={grey3}>2050</SwTypography>
    },
    {
        img: TonyFadell,
        title: <SwTypography bold={true}>Tony Fadell</SwTypography>,
        subTitle: <SwTypography color={grey3}>Future Shape</SwTypography>
    },
    {
        img: NicolasDessaigne,
        title: <SwTypography bold={true}>Nicolas Dessaigne</SwTypography>,
        subTitle: <SwTypography color={grey3}>Algolia</SwTypography>
    },
    {
        img: AdrianMcdermott,
        title: <SwTypography bold={true}>Adrian McDermott</SwTypography>,
        subTitle: <SwTypography color={grey3}>Zendesk</SwTypography>
    },
    {
        img: JaySimons,
        title: <SwTypography bold={true}>Jay Simons</SwTypography>,
        subTitle: <SwTypography color={grey3}>Bond Capital</SwTypography>
    },
    {
        img: GlennKelman,
        title: <SwTypography bold={true}>Glenn Kelman</SwTypography>,
        subTitle: <SwTypography color={grey3}>Redfin</SwTypography>
    }
];

const SwInvestors = () => {
    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const { t } = useTranslation();

    return (
        <>
            <Grid container={true} spacing={2}>
                <StyledInvestor item={true} xs={isSmallScreen ? 6 : 2}>
                    <Box display={'flex'} flexDirection={'column'}>
                        <StyledBox
                            alignItems={'center'}
                            boxSizing={'border-box'}
                            display={'flex'}
                            height={isSmallScreen ? 167 : 173}
                            justifyContent={'center'}
                            padding={'24px'}
                            width={'100%'}
                        >
                            <img alt={'Coatue'} src={Coatue} width={'100%'} />
                        </StyledBox>

                        <SwSpacer direction={'vertical'} spacing={2} />

                        <SwTypography bold={true}>Philippe Laffont</SwTypography>
                        <SwTypography color={grey3}>Coatue</SwTypography>
                    </Box>
                </StyledInvestor>

                <SwPeople grid={isSmallScreen ? 6 : 2} list={investors} />
            </Grid>

            <SwSpacer direction={'vertical'} spacing={5} />

            <Grid container={true} spacing={2}>
                <Grid item={true} md={6} xs={12}>
                    <StyledGrid>
                        <Box>
                            <SwTypography component={'strong'} variant={'h4'}>
                                {t('company_page.section_investors_quote_1_part_1')}
                            </SwTypography>

                            <SwSpacer direction={'vertical'} spacing={2} />

                            <SwTypography color={grey3}>
                                {t('company_page.section_investors_quote_1_part_2')}
                            </SwTypography>

                            <SwSpacer direction={'vertical'} spacing={2} />
                        </Box>

                        <Box alignItems={'center'} display={'flex'} gap={2}>
                            <img alt={'Philippe Laffont'} height={'44px'} src={PhilippeLafont} />

                            <Box>
                                <SwTypography bold={true}>Philippe Laffont</SwTypography>
                                <SwTypography>Coatue</SwTypography>
                            </Box>
                        </Box>
                    </StyledGrid>
                </Grid>

                <Grid item={true} md={6} xs={12}>
                    <StyledGrid>
                        <Box>
                            <SwTypography component={'strong'} variant={'h4'}>
                                {t('company_page.section_investors_quote_2_part_1')}
                            </SwTypography>

                            <SwSpacer direction={'vertical'} spacing={2} />

                            <SwTypography color={grey3}>
                                {t('company_page.section_investors_quote_2_part_2')}
                            </SwTypography>

                            <SwSpacer direction={'vertical'} spacing={2} />
                        </Box>

                        <Box alignItems={'center'} display={'flex'} gap={2}>
                            <img alt={'Philippe Laffont'} height={'44px'} src={TonyFadellThumbnail} />

                            <Box>
                                <SwTypography bold={true}>Tony Fadell</SwTypography>
                                <SwTypography>Future Shape</SwTypography>
                            </Box>
                        </Box>
                    </StyledGrid>
                </Grid>
            </Grid>
        </>
    );
};

export default SwInvestors;
