import React from 'react';
import { Grid, GridSize, styled } from '@mui/material';
import SwSpacer from '../../lib/components/bedrock/layout/SwSpacer';

const StyledBox = styled('div')`
    display: flex;
    flex-direction: column;
`;

const SwPeople: React.FunctionComponent<SwPeopleProps> = (props: SwPeopleProps) => {
    return (
        <>
            {props.list.map((item, index) => {
                return (
                    <Grid item={true} key={index} xs={props.grid}>
                        <StyledBox>
                            <img alt={'Portrait'} decoding={'async'} loading={'lazy'} src={item.img} width={'100%'} />
                            <SwSpacer direction={'vertical'} spacing={2} />

                            {item.title}

                            {props.spacing && <SwSpacer direction={'vertical'} spacing={1} />}

                            {item.subTitle}
                        </StyledBox>
                    </Grid>
                );
            })}
        </>
    );
};

interface SwPeopleProps {
    list: {
        img: any;
        title: JSX.Element;
        subTitle: JSX.Element;
    }[];
    grid: GridSize;
    spacing?: boolean;
}

export default SwPeople;
