import { ArrowRight } from '@phosphor-icons/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, useMediaQuery, useTheme, styled } from '@mui/material';
import { SwInternalLink } from '@lib/components/bedrock/navigation/SwInternalLink';
import { WEBSITE_MENU_ITEM } from '@lib/constants/routing';
import playButton from '../../assets/images/play-button.png';
import teamPhoto from '../../assets/images/team-photo.jpg';
import SwContainer from '../../components/v1/SwContainer';
import { grey3 } from '../../lib/components/bedrock/SwColors';
import SwSpacer from '../../lib/components/bedrock/layout/SwSpacer';
import SwTypography from '../../lib/components/bedrock/typography/SwTypography';

const StyledTeam = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledImage = styled(Box)<{ isSmallScreen: boolean }>`
    background: url(${teamPhoto});
    border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;
    width: 100%;
    height: ${({ isSmallScreen }) => (isSmallScreen ? '206px' : '492px')};
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledPlayButton = styled('div')`
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 100%;
    width: 72px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.25s;

    &:hover {
        background-color: rgba(255, 255, 255, 0.7);
        cursor: pointer;
        transition: 0.25s;
    }
`;

const SwChangeMaker = () => {
    const [play, setPlay] = useState(false);
    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const { t } = useTranslation();

    return (
        <SwContainer withMaxWidth={true}>
            <Grid container={true} spacing={2}>
                <Grid container={true} direction={'column'} item={true} md={3} xs={12}>
                    <Grid item={true}>
                        <SwTypography component={'h2'} variant={'h3'}>
                            {t('company_page.section_change_maker_title')}
                        </SwTypography>
                    </Grid>

                    <SwSpacer direction={'vertical'} spacing={2} />

                    <Grid item={true}>
                        <SwTypography color={grey3} variant={'body2'}>
                            {t('company_page.section_change_maker_subtitle')}
                        </SwTypography>
                    </Grid>

                    <SwSpacer direction={'vertical'} spacing={2} />

                    <Grid display={'flex'} item={true} justifyContent={'start'}>
                        <SwInternalLink to={WEBSITE_MENU_ITEM.jobs.link} variant={'secondary'}>
                            {t('join_us')} <ArrowRight />
                        </SwInternalLink>
                    </Grid>
                </Grid>
                <Grid item={true} md={9} xs={12}>
                    <StyledTeam>
                        {!play ? (
                            <Box width={'100%'}>
                                <StyledImage isSmallScreen={isSmallScreen}>
                                    <StyledPlayButton onClick={() => setPlay(true)}>
                                        <img alt={'Play button'} src={playButton} />
                                    </StyledPlayButton>
                                </StyledImage>
                            </Box>
                        ) : (
                            <iframe
                                allow={'autoplay; fullscreen; picture-in-picture'}
                                allowFullScreen={true}
                                frameBorder={'0'}
                                height={isSmallScreen ? '205px' : '480px'}
                                src={`https://player.vimeo.com/video/732113425?h=2c85d92230&autoplay=${play}&color=ffffff&title=0&byline=0&portrait=0`}
                                title={'Join us'}
                                width={'100%'}
                            />
                        )}
                    </StyledTeam>
                </Grid>
            </Grid>
        </SwContainer>
    );
};

export default SwChangeMaker;
