import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, styled } from '@mui/material';
import { GatsbyImage } from 'gatsby-plugin-image';
import SwFlexBox from '@lib/components/bedrock/layout/SwFlexBox';
import { SwGrid, repeat } from '@lib/components/bedrock/layout/SwGrid';
import { AspectRatios } from '@lib/enums/apectRatios';
import SwLine from '../../components/v1/SwLine';
import { grey5, grey3 } from '../../lib/components/bedrock/SwColors';
import SwSpacer from '../../lib/components/bedrock/layout/SwSpacer';
import SwTypography from '../../lib/components/bedrock/typography/SwTypography';

const ImageContainer = styled('div')`
    position: relative;
    z-index: 0;
    aspect-ratio: ${AspectRatios.landscape};
    overflow: hidden;
    border-radius: 8px;
    margin-block-end: 8px;
`;

const SwLeadership = ({ leadershipsEdges }) => {
    const order = ['Delacour', 'Güller', 'Chaze', 'Denormandie', 'Brauch', 'Bettin', 'Vincent', 'Bouhadja', 'Valencia'];

    const { t } = useTranslation();

    const leaderships = order
        .map((lastName) => leadershipsEdges.edges.find((edge) => edge.node.lastName === lastName)?.node)
        .filter(Boolean);

    return (
        <Grid item={true} xs={12}>
            <SwLine color={grey5} spacingTop={4} />

            <SwTypography color={grey3} component={'h3'} variant={'h4'}>
                {t('company_page.section_leaderships_title')}
            </SwTypography>

            <SwSpacer direction={'vertical'} spacing={4} />

            <SwGrid columns={[repeat('auto-fill', 'minmax(248px, 1fr)')]}>
                {leaderships.map((people) => (
                    <SwFlexBox flexDirection={'column'} gap={8} key={people.id}>
                        <ImageContainer>
                            <GatsbyImage
                                alt={people.photo.alt}
                                image={people.photo.gatsbyImageData}
                                objectFit={'cover'}
                                style={{ width: '100%', height: '100%' }}
                            />
                        </ImageContainer>
                        <SwTypography component={'p'} variant={'h4'}>
                            {people.firstName} {people.lastName}
                        </SwTypography>
                        <SwTypography color={grey3}>{people.role}</SwTypography>
                    </SwFlexBox>
                ))}
            </SwGrid>
        </Grid>
    );
};

export default SwLeadership;
