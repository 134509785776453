import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Grid, useMediaQuery, useTheme, styled } from '@mui/material';
import AnaBusto from '../../assets/images/profiles/ana-busto.png';
import ElizabethLaville from '../../assets/images/profiles/elizabeth-laville.png';
import NicolasReboud from '../../assets/images/profiles/nicolas-reboud.png';
import VanessaLaubin from '../../assets/images/profiles/vanessa-laubin.png';
import turbine from '../../assets/images/turbine.png';
import SwContainer from '../../components/v1/SwContainer';
import { grey2, grey5, skyBlueMedium, white } from '../../lib/components/bedrock/SwColors';
import SwLine from '../../lib/components/bedrock/layout/SwLine';
import SwSpacer from '../../lib/components/bedrock/layout/SwSpacer';
import SwLink from '../../lib/components/bedrock/typography/SwLink';
import SwTypography from '../../lib/components/bedrock/typography/SwTypography';
import SwPeople from './SwPeople';

const StyledTurbine = styled('div')`
    background-color: ${skyBlueMedium};
    height: 457px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

const StyledDiv = styled('div')`
    background-color: ${skyBlueMedium};
    padding: 16px;
    border-radius: 8px;
`;

const committee = [
    {
        img: ElizabethLaville,
        title: (
            <SwTypography bold={true} color={white} component={'p'} variant={'h4'}>
                Elisabeth Laville
            </SwTypography>
        ),
        subTitle: <SwTypography color={white}>Utopies</SwTypography>
    },
    {
        img: AnaBusto,
        title: (
            <SwTypography bold={true} color={white} component={'p'} variant={'h4'}>
                Ana Busto
            </SwTypography>
        ),
        subTitle: <SwTypography color={white}>Former EVP Brand and Communications at Engie</SwTypography>
    },
    {
        img: NicolasReboud,
        title: (
            <SwTypography bold={true} color={white} component={'p'} variant={'h4'}>
                Nicolas Reboud
            </SwTypography>
        ),
        subTitle: <SwTypography color={white}>Founder of Shine</SwTypography>
    },
    {
        img: VanessaLaubin,
        title: (
            <SwTypography bold={true} color={white} component={'p'} variant={'h4'}>
                Vanessa Laubin
            </SwTypography>
        ),
        subTitle: <SwTypography color={white}>Climate Expert at Projections CC</SwTypography>
    }
];

const SwImpact = () => {
    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const { t } = useTranslation();

    return (
        <>
            <SwContainer withMaxWidth={true}>
                <Grid container={true} spacing={3}>
                    <Grid container={true} direction={'column'} item={true} md={3} xs={12}>
                        <Grid item={true}>
                            <SwTypography color={white} component={'h2'} variant={'h3'}>
                                {t('company_page.section_impact_title')}
                            </SwTypography>
                        </Grid>

                        <SwSpacer direction={'vertical'} spacing={2} />

                        <Grid item={true}>
                            <SwTypography color={skyBlueMedium} variant={'body2'}>
                                {t('company_page.section_impact_subtitle')}
                            </SwTypography>
                        </Grid>
                    </Grid>
                    <Grid item={true} md={9} xs={12}>
                        <StyledTurbine>
                            <img alt={'Turbine'} src={turbine} width={'100%'} />
                        </StyledTurbine>
                    </Grid>
                </Grid>
            </SwContainer>

            <SwSpacer direction={'vertical'} spacing={4} />

            <SwContainer withMaxWidth={true}>
                <Grid container={true} spacing={2}>
                    <Grid item={true} md={4} xs={12}>
                        <StyledDiv>
                            <SwTypography component={'h3'} variant={'h4'}>
                                {t('company_page.impact_card_1_title')}
                            </SwTypography>
                            <SwSpacer direction={'vertical'} spacing={2} />
                            <SwTypography color={grey2}>
                                <Trans i18nKey={'company_page.impact_card_1_content'}>
                                    <SwLink color={grey2} href={'https://societeamission.com/'} useNewTab={true} />
                                </Trans>
                            </SwTypography>
                        </StyledDiv>
                    </Grid>
                    <Grid item={true} md={4} xs={12}>
                        <StyledDiv>
                            <SwTypography component={'h3'} variant={'h4'}>
                                {t('company_page.impact_card_2_title')}
                            </SwTypography>
                            <SwSpacer direction={'vertical'} spacing={2} />
                            <SwTypography color={grey2}>
                                <Trans i18nKey={'company_page.impact_card_2_content'}>
                                    <SwLink
                                        color={grey2}
                                        href={'https://www.bcorporation.net/en-us/find-a-b-corp/company/sweep'}
                                        useNewTab={true}
                                    />
                                </Trans>
                            </SwTypography>
                        </StyledDiv>
                    </Grid>
                    <Grid item={true} md={4} xs={12}>
                        <StyledDiv>
                            <SwTypography component={'h3'} variant={'h4'}>
                                {t('company_page.impact_card_3_title')}
                            </SwTypography>
                            <SwSpacer direction={'vertical'} spacing={2} />
                            <SwTypography color={grey2}>{t('company_page.impact_card_3_content')}</SwTypography>
                        </StyledDiv>
                    </Grid>
                </Grid>
            </SwContainer>

            <SwContainer withMaxWidth={true}>
                <Grid container={true}>
                    <Grid item={true} xs={12}>
                        <SwLine color={grey5} direction={'horizontal'} spacingTop={5} />

                        <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
                            <SwTypography color={white} component={'h3'} variant={'h4'}>
                                {t('company_page.section_impact_committee_title')}
                            </SwTypography>

                            <Grid item={true} md={4} xs={12}>
                                <SwTypography color={skyBlueMedium} variant={'body1'}>
                                    {t('company_page.section_impact_committee_subtitle')}
                                </SwTypography>
                            </Grid>
                        </Box>

                        <SwSpacer direction={'vertical'} spacing={4} />
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Grid container={true} spacing={2}>
                            <SwPeople grid={isSmallScreen ? 6 : 3} list={committee} />
                        </Grid>
                    </Grid>
                </Grid>
            </SwContainer>
        </>
    );
};

export default SwImpact;
